<template>
  <div class="flex-grow">
    <!-- Panel with the event info -->
    <m-card class="cursor-pointer mb-4" :class="{ 'is-closed': !isEditorOpen }">
      <div class="lg:px-3">
        <div
          class="flex justify-between min-w-0"
          :class="
            editorHasNotes && editorActionsTotal > 0
              ? 'mr-14 lg:mr-0'
              : 'mr-4 lg:mr-0'
          "
          @click.self="toggleEditorOpen"
        >
          <div class="lg:flex items-center my-1 min-w-0">
            <h3
              class="
                text-grey1
                font-normal
                text-xl
                overflow-hidden
                whitespace-nowrap
                overflow-ellipsis
              "
              data-recording-sensitive
              @click.self="toggleEditorOpen"
            >
              {{ event.summary }}
            </h3>

            <div class="flex lg:mt-1">
              <!-- any icons, decide here if show or not -->
              <div
                data-recording-sensitive
                style="min-width: 6.5rem"
                @click="toggleEditorOpen"
              >
                <MCIDate
                  :startDateTime="getDateOrDateTime(event.start)"
                  :endDateTime="getDateOrDateTime(event.end)"
                ></MCIDate>
              </div>
              <div class="absolute lg:static right-8 -mt-7 lg:-mt-px flex">
                <MCINotes
                  v-if="editorHasNotes"
                  @click="onNotesClick"
                  v-bind:isActive="!showOnlyActions && isEditorOpen"
                ></MCINotes>
                <MCIActions
                  v-if="editorActionsTotal > 0"
                  v-bind:captionExpanded="showOnlyActions && isEditorOpen"
                  v-bind:isActive="showOnlyActions && isEditorOpen"
                  v-bind:actionsTotal="editorActionsTotal"
                  v-bind:actionsCompleted="editorActionsCompleted"
                  @click="onActionsClick"
                ></MCIActions>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div class="hidden lg:flex mt-1" v-show="isEditorOpen">
              <meeting-guests
                :guests="meetingAttendeesWithOnlineStatus"
                v-bind:showOnlineStatus="true"
                v-bind:showDetails="true"
              ></meeting-guests>
            </div>
          </transition>
        </div>
        <div v-for="i in 3" :key="i">
          <m-placeholder
            class="mt-2 pt-3 mb-2 rounded-lg my-auto"
            v-show="!editorCounted"
          ></m-placeholder>
        </div>
        <div class="relatedMeetingEditor">
          <div class="mb-2">
            <!-- v-if as we show only 1, private/public would be separate components -->
            <notes-editor
              :isPrivate="isPrivate"
              class="w-full text-black"
              :meetingId="event.id"
              :nextMeetingDate="nextMeetingDate"
              :calendarId="calendarId"
              :summary="event.summary"
              :guests="isPrivate ? myselfOnlyAttendee : meetingAttendees"
              :user="user"
              :readOnly="isReadOnly"
              :inRelatedMeeting="true"
              :showOnlyActions="showOnlyActions"
              @editorCount="onEditorCount"
              @participants="updateParticipants($event)"
              @tokenExpired="$emit('refreshToken')"
              :getNote="getNoteCounter"
              @noteHTML="onNoteHTML"
              @loaded="onEditorLoaded"
              @updated="onEditorUpdate"
              :getIncompleteActions="getIncompleteActionsLocal"
              @incompleteactions="onIncompleteActions"
              :actionsToRemoveCounter="actionsToRemoveCounter"
              :actionsToRemove="actionsToRemove"
              @actionsRemoved="onActionsRemoved"
            >
            </notes-editor>

            <m-button
              class="mt-1 mr-2"
              button-style="btn-tertiary"
              @click="goToMeeting"
              icon="📅"
            >
              Go to meeting
            </m-button>
            <m-button
              v-if="editorActionsTotal > 0 && editorHasNotes"
              class="mt-1"
              button-style="btn-tertiary"
              @click="onNotesButtonClick"
              :icon="!showOnlyActions ? '✅' : '🗒'"
            >
              <div v-if="!showOnlyActions" class="flex">Show actions only</div>
              <div v-else class="flex">Show notes</div>
            </m-button>
          </div>
        </div>
      </div>
    </m-card>
  </div>
</template>

<script>
import NotesEditor from '@/components/Editor2';
import {
  getMeetingAttendees,
  getRecurringInfoFromEvent,
} from '@/components/Utils';
import { debounce } from 'debounce';
import { getNextRecurringEvent } from '@/api/Google';
import MCard from '@/components/UI/MCard';
import MButton from '@/components/UI/MButton';
import MCIActions from '@/components/UI/MeetingCardIcons/Actions';
import MCINotes from '@/components/UI/MeetingCardIcons/Notes';
import MCIDate from '@/components/UI/MeetingCardIcons/Date';
import MeetingGuests from '@/components/MeetingGuest/MeetingGuests';
import MPlaceholder from '@/components/UI/MPlaceholder';

export default {
  name: 'RelatedMeeting',
  components: {
    MCIDate,
    MCard,
    MButton,
    MCIActions,
    MCINotes,
    MeetingGuests,
    NotesEditor,
    MPlaceholder,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    calendarId: {
      type: String,
      required: true,
    },
    openEditor: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    reportNotes: {
      type: Boolean,
      required: false,
      default: false,
    },
    getIncompleteActions: {
      type: Number,
      required: false,
      default: 0,
    },
    actionsToRemoveCounter: {
      type: Number,
      required: false,
      default: 0,
    },
    actionsToRemove: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      editorHasNotes: false, // does editor contain any notes
      editorActionsTotal: 0, // total number of actions
      editorActionsCompleted: 0, // just completed
      showOnlyActions: false, // if true, filters out everything else then actions in editor
      editorCounted: false, // set to true with first editor count
      isEditorOpen: false,
      onlineParticipants: [],
      nextMeetingDate: '',
      getNoteCounter: 0,
      debouncedNotes: null,
      getActionsLocalCounter: 0,
    };
  },

  mounted() {
    this.isEditorOpen = this.openEditor;

    // unlike active meeting, related meeting is mounted AFTER we have event
    // so we can assume the event object already exist in mounted()
    this.getNextMeetingDate();
  },
  computed: {
    getIncompleteActionsLocal() {
      return this.getIncompleteActions + this.getActionsLocalCounter;
    },
    myselfOnlyAttendee() {
      const email = this.$gAuth?.basicProfile?.getEmail();
      return email ? [{ email: email }] : [];
    },
    meetingAttendeesWithOnlineStatus() {
      let attendees = this.meetingAttendees;
      const withOnline = attendees.map((a) => {
        const onlineBool = this.onlineParticipants.includes(a.email);
        this.$set(a, 'online', onlineBool);
        return a;
      });
      return withOnline;
    },
    isReadOnly() {
      const plan = this.$store.getters['plan'];
      const era = this.$store.getters['era'];
      const trialConsumed = this.$store.getters['trialConsumed'];
      if (plan == 0 && era == 1 && trialConsumed) {
        return true;
      }
      return false;
    },
    meetingAttendees() {
      return getMeetingAttendees(this.event);
    },
    isAuthenticated() {
      return this.$gAuth.isAuthorized;
    },
  },
  methods: {
    onIncompleteActions($event) {
      this.$emit('incompleteactions', {
        ...$event,
        event: this.event,
        cid: this.calendarId,
        initial: this.getIncompleteActionsLocal === 1, // first one is on load so we can detect it is initialised
      });
    },
    onActionsRemoved({ count }) {
      console.log(count);
    },
    onEditorUpdate() {
      if (!this.reportNotes) return;

      this.debouncedNotes?.clear();
      this.debouncedNotes = debounce(() => this.getNoteCounter++, 3000);
      this.debouncedNotes();
    },
    onNoteHTML($event) {
      // send to parent
      this.$emit('sendnotes', $event);
    },
    onEditorLoaded() {
      // get the notes right after they are loaded
      if (this.reportNotes) this.getNoteCounter++;

      this.getActionsLocalCounter++;
    },
    goToMeeting() {
      this.$router.push({
        name: 'meeting',
        params: { id: this.event.id, calendarId: this.calendarId },
        query: { private: this.isPrivate ? null : undefined },
      });
    },
    getNextMeetingDate() {
      const recurringInfo = getRecurringInfoFromEvent(this.event);

      if (recurringInfo.id && recurringInfo.end) {
        getNextRecurringEvent(
          this.calendarId,
          recurringInfo.id,
          recurringInfo.end
        ).then((r) => {
          if (!r) return;
          if (r.items.length == 1) {
            const e = r.items[0];
            if (e.start.dateTime) {
              this.nextMeetingDate = e.start.dateTime.split('T')[0];
            } else if (e.start.date) {
              this.nextMeetingDate = e.start.date;
            }
          }
        });
      }
    },

    updateParticipants(participants) {
      if (this.isPrivate) return;
      // consider only unique ones
      if (participants)
        this.onlineParticipants = [
          ...new Set(participants.map((p) => p.email)),
        ];
    },
    toggleEditorOpen() {
      this.isEditorOpen = !this.isEditorOpen;

      const props = {
        meeting_id: this.event.id,
        inRelatedMeeting: true,
        isPrivate: this.isPrivate,
        value: this.isEditorOpen ? 'expand' : 'contract',
      };
      window.meetric.track('Meeting interaction', props);
    },
    onEditorCount(count) {
      if (
        this.editorActionsTotal > 0 &&
        count.total === 0 &&
        this.showOnlyActions
      ) {
        // remove filter as the button will be removed for no actions
        this.showOnlyActions = false;
      }

      this.editorActionsTotal = count.total;
      this.editorActionsCompleted = count.completed;
      this.editorHasNotes = count.notes > 0;
      // console.log('cnt', count, this.event.id, this.isPrivate);
      if (!this.editorCounted) {
        this.countEditor();
        this.editorCounted = true;
      }
    },

    countEditor() {
      if (this.editorActionsCompleted < this.editorActionsTotal) {
        this.isEditorOpen = true;
        this.showOnlyActions = true;
      }
    },
    getDateOrDateTime(item) {
      return item.dateTime ? item.dateTime : item.date;
    },
    onActionsClick: function () {
      if (this.isEditorOpen && !this.showOnlyActions) {
        this.showOnlyActions = true;
      } else if (this.isEditorOpen && this.showOnlyActions) {
        this.isEditorOpen = false;

        // this is HACK so the animation scroll finish first
        const _this = this;
        setTimeout(function () {
          _this.showOnlyActions = true;
        }, 250);
      } else {
        this.isEditorOpen = true;
        this.showOnlyActions = true;
      }

      const props = {
        meeting_id: this.event.id,
        inRelatedMeeting: true,
        isPrivate: this.isPrivate,
        value: 'filterActionsClick',
      };
      window.meetric.track('Meeting interaction', props);
    },
    onNotesClick: function () {
      if (this.isEditorOpen && this.showOnlyActions) {
        this.showOnlyActions = !this.showOnlyActions;
      } else if (this.isEditorOpen && !this.showOnlyActions) {
        this.isEditorOpen = false;
      } else {
        this.isEditorOpen = true;
        this.showOnlyActions = false;
      }
      const props = {
        meeting_id: this.event.id,
        inRelatedMeeting: true,
        isPrivate: this.isPrivate,
        value: 'filterNotesClick',
      };
      window.meetric.track('Meeting interaction', props);
    },
    onNotesButtonClick: function () {
      this.showOnlyActions = !this.showOnlyActions;

      const props = {
        meeting_id: this.event.id,
        inRelatedMeeting: true,
        isPrivate: this.isPrivate,
        // toggled above so reverse condition
        value: this.showOnlyActions ? 'Just actions' : 'All notes',
      };
      window.meetric.track('Meeting interaction', props);
    },
  },
};
</script>

<style scoped>
/* everything in the editor except for action-item */
.relatedMeetingEditor
  >>> .filter-show-actions
  > div.ProseMirror
  > *:not(.action-item):not(ul, ol) {
  display: none;
}

.relatedMeetingEditor >>> .filter-show-actions > div.ProseMirror li {
  visibility: hidden;
}

.relatedMeetingEditor >>> .filter-show-actions > div.ProseMirror li > p,
.relatedMeetingEditor
  >>> .filter-show-actions
  > div.ProseMirror
  li.agendaitem-child
  > div {
  height: 0;
  margin-bottom: 0;
}
.relatedMeetingEditor
  >>> .filter-show-actions
  > div.ProseMirror
  li.actionitem-child {
  visibility: visible;
  margin-bottom: 0.2rem;
}

.relatedMeetingEditor {
  @apply overflow-auto;
  max-height: 2000px;
  transition: max-height 0.4s ease-in-out 0.1s;
}

.is-closed .relatedMeetingEditor {
  max-height: 0;
  transition: max-height 0.4s ease-in-out 0.1s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
