<template>
  <div>
    <div v-if="!finished && started" class="flex">
      <!-- Timer alert -->
      <transition name="alert">
        <div class="flex items-center" v-if="showLabel">
          <div class="shadow-sm flex rounded-xl mr-2 bg-white dark:bg-base5">
            <div
              class="rounded-l-xl flex items-center pl-4 pr-2 py-2"
              :class="labelBg"
            >
              {{ message }}
            </div>
            <div
              class="
                flex
                items-center
                rounded-r-xl
                text-sm
                cursor-pointer
                px-2
                bg-opacity-50
                opacity-75
              "
              :class="labelBg"
              style=""
              @click="showLabel = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="stroke-current m-0"
                width="11"
                height="11"
                viewBox="0 0 24 24"
                stroke-width="4"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>
        </div>
      </transition>

      <!-- Timer clock -->
      <svg width="60" height="60" class="fill-current text-grey2">
        <defs>
          <filter
            id="shadow"
            filterUnits="userSpaceOnUse"
            width="100"
            height="100"
          >
            <feDropShadow
              dx="0"
              dy="1"
              stdDeviation="2"
              flood-color="#b5b5b5"
              flood-opacity="1"
            />
          </filter>
        </defs>
        <g>
          <path
            id="timerCirclePath2"
            class="fill-current text-white dark:text-base5"
            stroke-width="3"
            stroke-linecap="round"
            :d="fullCircle"
          />
          <path
            id="timerCirclePath"
            fill="none"
            class="stroke-current opacity-25"
            :class="timerCircleClass"
            stroke-width="3"
            stroke-linecap="round"
            :d="fullCircle"
          />
          <path
            id="timerCircle"
            fill="none"
            stroke-width="8"
            stroke-linecap="round"
            :d="timerCirclePath"
            :class="timerCircleClass"
            filter="url(#shadow)"
          />
          <circle
            id="timerDot"
            class="fill-current animate-pulse text-white"
            r="2"
            :cx="timerDotX"
            :cy="timerDotY"
          />
          <text
            id="timerFinishedText"
            class="text-grey1 text-ss"
            x="50%"
            y="50%"
            font-family="Inter, Karla, sans-serif;"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {{ !timerLabel && !timerUnit ? 'End' : '' }}
          </text>
          <text
            id="timerLabel"
            class="text-grey1 text-sm"
            x="50%"
            y="44%"
            font-family="Inter, Karla, sans-serif;"
            font-weight="500"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {{ timerLabel }}
          </text>
          <text
            id="timerUnit"
            class="text-grey1 text-xs"
            x="50%"
            y="63%"
            font-family="Inter, Karla, sans-serif;"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {{ timerUnit }}
          </text>
        </g>
      </svg>
    </div>
    <div v-if="finished || !started">
      <!-- Show nothing -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Timer',
  components: {},
  data() {
    return {
      interval: 3 * 1000,
      timeout: null,
      started: moment().isAfter(this.start),
      finished: moment().isAfter(this.end),
      timerCirclePath: '',
      timerDotX: 0,
      timerDotY: 0,
      timerCircleClass: '',
      timerLabel: '',
      timerUnit: 'min',
      labelBg: '',
      message: '',
      showLabel: false,

      tenMinutes: 600,
      yellow: false,
      red: false,
    };
  },
  computed: {
    startTime() {
      return moment(this.start);
    },
    endTime() {
      return moment(this.end);
    },
    totalDuration() {
      return (this.endTime - this.startTime) / 1000;
    },
    fullCircle() {
      return describeArc(this.totalDuration, this.totalDuration);
    },
  },
  watch: {
    showLabel: function (newVal) {
      if (
        !newVal &&
        this.labelBg == 'bg-alerterrorlight bg-opacity-25 text-alerterror' &&
        this.message == 'Doing overtime!'
      ) {
        this.$emit('isOver', true);
      }
    },
  },
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.runTimer();
  },
  methods: {
    runTimer() {
      this.started = moment().isAfter(this.start);
      if (!this.started) {
        this.timeout = setTimeout(
          this.startTimer,
          -moment().diff(this.startTime)
        );
      } else {
        this.timeout = setTimeout(this.runTimer, this.interval);
        this.updateCircle();
      }
    },
    startTimer() {
      this.runTimer();
      setTimeout(() => {
        this.message = 'Here we go!';
        this.showLabel = true;
        this.labelBg =
          'bg-alertvalidationlight bg-opacity-25 text-alertvalidation';
        this.closeLabelSoon();
      }, 1000);
    },
    closeLabelSoon() {
      //console.log('Setting up timer');
      setTimeout(() => (this.showLabel = false), 6000);
    },
    updateCircle() {
      const timeRemaining = (this.endTime - Date.now()) / 1000;
      // console.log('rem', (timeRemaining / this.totalDuration).toFixed(2));
      this.timerCircleClass = arcClass(timeRemaining, this.totalDuration);
      if (timeRemaining < 0) {
        // Finished
        this.timerLabel = '';
        this.timerUnit = '';
        this.message = 'Doing overtime!';
        this.showLabel = true;
        this.labelBg = 'bg-alerterrorlight bg-opacity-25 text-alerterror';
        this.timerCirclePath = describeArc(
          this.totalDuration,
          this.totalDuration
        );
        clearTimeout(this.timeout);
        setTimeout(() => {
          this.showLabel = false;
        }, 5000);
      } else {
        this.timerCirclePath = describeArc(timeRemaining, this.totalDuration);
        this.timerDotX = endX(timeRemaining, this.totalDuration);
        this.timerDotY = endY(timeRemaining, this.totalDuration);
        if (timeRemaining < 60) {
          this.interval = 1000;
          this.timerLabel = Math.floor(timeRemaining);
          this.timerUnit = 'sec';
        } else {
          let time = (timeRemaining / 60).toFixed(2);
          // console.log('time', time);
          this.timerLabel =
            time - Math.floor(timeRemaining / 60) < 0.5
              ? Math.floor(timeRemaining / 60)
              : Math.ceil(timeRemaining / 60);
          // this.timerLabel = Math.floor(timeRemaining / 60);
          this.timerUnit = 'min';
          if (
            !this.red &&
            this.timerLabel == 5 &&
            this.totalDuration > this.tenMinutes
          ) {
            this.message = 'Wrap it up!';
            this.red = true;
            this.showLabel = true;
            this.labelBg = 'bg-alerterrorlight bg-opacity-25 text-alerterror';
            this.closeLabelSoon();
          } else if (
            !this.yellow &&
            (timeRemaining / this.totalDuration).toFixed(2) == 0.5
          ) {
            this.message = 'Half way point!';
            this.showLabel = true;
            this.yellow = true;
            this.labelBg =
              ' bg-alertwarninglight bg-opacity-25 text-alertwarning';
            this.closeLabelSoon();
          }
        }
      }
    },
  },
};

const canvasSize = 60;
const x = canvasSize / 2;
const y = canvasSize / 2;
const radius = (3 * canvasSize) / 8;
const startingPoint = 'M ' + x + ' ' + canvasSize / 8;
const tenMinutes = 600;

function arcClass(portion, total) {
  const proportion = portion / total;
  const proportionFixed = proportion.toFixed(2);
  let time = (portion / 60).toFixed(2);
  // console.log('proportion', proportion);
  const inMinutes =
    time - Math.floor(portion / 60) < 0.5
      ? Math.floor(portion / 60)
      : Math.ceil(portion / 60);
  if (proportionFixed > 0.5) {
    return 'stroke-current shadow text-alertvalidation';
  }
  if (
    (total < tenMinutes && proportion > 0.1) ||
    (total > tenMinutes && inMinutes > 5)
  ) {
    return 'stroke-current shadow text-alertwarning';
  }

  return 'stroke-current shadow text-alerterror';

  // return 'stroke-current shadow text-base2';
}

function describeArc(portion, total) {
  let arcSweep = 1;
  if (portion / total > 0.5) {
    arcSweep = 0;
  }
  if (portion == total) {
    arcSweep = 1;
  }
  let d = [
    startingPoint,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    1,
    endX(portion, total),
    endY(portion, total),
  ].join(' ');
  if (portion == total) {
    d += ' Z';
  }
  return d;
}

function endX(portion, total) {
  return x + radius * Math.cos(angle(portion, total));
}

function endY(portion, total) {
  return y - radius * Math.sin(angle(portion, total));
}

function angle(portion, total) {
  let a = Math.PI * ((2 * portion) / total + 0.5);
  if (portion == total) {
    a += 0.01;
  }
  return a;
}
</script>

<style>
.alert-enter-active,
.alert-leave-active {
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

.alert-enter,
.alert-leave-to {
  transform: translateX(+20%);
  opacity: 0;
}

/* Timer dot */
.dark #timerDot {
  @apply text-black;
}
</style>
