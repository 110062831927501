<template>
  <!-- Main file representing specific meeting page (index) -->
  <div class="flex flex-grow flex-col min-w-0 lg:ml-20 lg:mr-8">
    <!-- valid meeting ID -->
    <div class="flex-grow lg:mt-5">
      <active-meeting
        :activeMeetingEvent="activeMeetingEvent"
        :nextMeetingDate="activeNextMeetingDate"
        :nextRecurringMeeting="activeNextRecurringEvent"
        :isThereReminderForNextMeeting="isThereReminderForNextMeeting"
        :isThereAutoCancelForNextMeeting="isThereAutoCancelForNextMeeting"
        :calendarId="mainEventCalId"
        :meetingId="id"
        :areThereRelatedMeetings="relatedMeetings.length > 0"
        :loadingGoogleEvent="loadingGoogleEvent"
        :expandEditorOnLoad="relatedMeetingsShowed.expandEditor"
        :userIsGuest="userIsGuest"
        :user="user"
        :isPrivate="isPrivate"
        :isPrivateReadonly="isPrivateReadonly"
        :titleUpdated="titleUpdatedCounter"
        @toggleprivate="onPrivateToggle"
        @patchMeetingTitle="onPatchMeetingTitle"
        @refreshToken="refreshToken"
        :lastMeetingNotesPrivate="lastRelatedNotesPrivate"
        :lastMeetingNotesPublic="lastRelatedNotesPublic"
        @refreshLastMeetingNotesPrivate="refreshLastMeeting(true)"
        @refreshLastMeetingNotesPublic="refreshLastMeeting(false)"
        :hasRelatedIncompleteActions="hasIncompleteActions"
        @getleftovers="onGetLeftovers"
        :incompleteActions="incompleteActions"
        @removeActions="onRemoveActions"
      ></active-meeting>

      <template v-if="defer(1)">
        <div v-if="isAuthenticated && userIsGuest">
          <div
            class="
              flex
              pt-6
              pb-4
              sticky
              top-8
              lg:top-0
              z-10
              mx-0
              lg:-mx-1
              bottom-fade
            "
          >
            <div class="flex ml-8 lg:ml-1">
              <m-heading data-tooltip="related-meeting-heading">
                {{ relatedMeetingsShowed.title }}
              </m-heading>

              <hover-tool-tip
                :label="timeMachineTitleHover"
                selector='[data-tooltip="related-meeting-heading"]'
                :placement="isExtension ? 'bottom' : 'right'"
                :offset="isExtension ? 0 : 8"
                url="https://meetric.notion.site/The-Time-Machine-c54545d53da64d9ca054354128403b8e"
              ></hover-tool-tip>
            </div>
            <div
              class="
                flex flex-grow
                border-solid border border-l-0 border-r-0 border-b-0
                mt-4
                mx-5
                border-base2 border-opacity-5
                dark:border-base5
              "
            ></div>
            <div
              class="flex mr-8 lg:mr-1 relative"
              data-tooltip="related-meeting-heading-right"
            >
              <div
                class="
                  flex
                  items-center
                  text-base1 text-base
                  opacity-50
                  text-right
                "
              >
                <span v-if="relatedMeetingsShowed.isEmpty">
                  No related notes</span
                >
                <template v-else>
                  <span v-if="isPrivate">Private</span>
                  <span v-else>Shared</span>
                </template>
              </div>
              <hover-tool-tip
                :label="hoverRight.text"
                selector='[data-tooltip="related-meeting-heading-right"]'
                :placement="isExtension ? 'bottom' : 'left'"
                :offset="isExtension ? 0 : 8"
                :url="hoverRight.url"
              ></hover-tool-tip>
            </div>
          </div>

          <div v-show="isRelMeetLoaded" class="flex flex-col">
            <div
              v-show="relatedMeetingsShowed.isEmpty"
              class="text-grey2 text-base ml-8 mr-8 lg:ml-0"
            >
              This section will automatically show meeting notes from past
              meetings (<router-link
                class="underline cursor-pointer opacity-75 hover:opacity-100"
                :to="{ name: 'profile' }"
                >update</router-link
              >
              the rules)
            </div>
            <div v-show="!isPrivate">
              <template v-for="(event, idx) in relatedMeetingsPublic">
                <related-meeting
                  :reportNotes="idx === 0 ? true : false"
                  @sendnotes="lastMeetingNotes($event, false)"
                  :key="event.id + 'false'"
                  :isPrivate="false"
                  :calendarId="mainEventCalId"
                  :event="event"
                  :user="user"
                  :getIncompleteActions="getIncompleteActionsFromRelatedPublic"
                  :actionsToRemove="actionsToRemove[event.id]"
                  :actionsToRemoveCounter="actionsToRemovePublicCounter"
                  @incompleteactions="onIncompleteActions"
                  @refreshToken="refreshToken"
                />
              </template>
              <div
                v-if="accountPlan <= 1 && relatedMeetings.length >= 5"
                class="
                  text-grey2 text-sm text-center
                  opacity-75
                  mx-8
                  lg:mx-0
                  mt-6
                  mb-14
                  lg:mb-6
                "
              >
                Showing up to 5 related meetings in the past 45 days. Premium
                accounts have
                <a-link
                  href="https://meetric.notion.site/Meetric-Pro-231289c089e948b79f235c006e83c582"
                  segmentName="Premium intent: solo, history"
                  >unlimited history</a-link
                >
              </div>
            </div>
            <!-- private notes -->
            <div v-show="isPrivate">
              <template v-for="(event, idx) in relatedMeetingsPrivate">
                <related-meeting
                  :reportNotes="idx === 0 ? true : false"
                  @sendnotes="lastMeetingNotes($event, true)"
                  :key="event.id + 'true'"
                  :isPrivate="true"
                  :calendarId="mainEventCalId"
                  :event="event"
                  :user="user"
                  :getIncompleteActions="getIncompleteActionsFromRelatedPrivate"
                  :actionsToRemove="actionsToRemove[event.id]"
                  :actionsToRemoveCounter="actionsToRemovePrivateCounter"
                  @incompleteactions="onIncompleteActions"
                  @refreshToken="refreshToken"
                />
              </template>
              <div
                v-if="accountPlan <= 1 && relatedMeetings.length >= 5"
                class="
                  text-grey2 text-sm text-center
                  opacity-75
                  mx-8
                  lg:mx-0
                  mt-6
                  mb-14
                  lg:mb-6
                "
              >
                Showing up to 5 related meetings in the past 45 days. Premium
                accounts have
                <a-link
                  href="https://meetric.notion.site/Meetric-Pro-231289c089e948b79f235c006e83c582"
                  segmentName="Premium intent: solo, history"
                  >unlimited history</a-link
                >
              </div>
            </div>

            <!--S LOAD MORE -->
            <div
              v-if="relatedMeetings.length == 0 || hideLoadMoreBtn"
              class="h-12"
            ></div>
            <div v-else>
              <div
                v-if="
                  accountPlan >= 2 &&
                  relatedMeetings.length != 0 &&
                  !hideLoadMoreBtn
                "
                class="flex justify-center mb-14 lg:mb-6"
              >
                <m-button
                  button-style="btn-tertiary"
                  :pending="loadingMoreRelated"
                  icon="📋"
                  class="mr-2"
                  @click="loadMoreRelated()"
                >
                  Load more
                </m-button>
              </div>
              <!--E LOAD MORE -->
            </div>
          </div>
          <div
            v-show="!isRelMeetLoaded"
            class="flex flex-col px-5 lg:px-0 mb-5"
          >
            <div v-for="i in 3" :key="i">
              <m-placeholder
                class="card-shadow border-solid px-2 mt-2 h-8 rounded-lg"
              ></m-placeholder>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getCalendarEventById, getNextRecurringEvent } from '@/api/Google';

import {
  getMeetricRelatedMeetings,
  getCalendarEventFromMeetric,
  addGuests,
  patchMeetingTitle, // checkMeetingAccess,
  getMeetingReminder,
  getMeetingAutoCancel,
} from '@/api/Cherry';

import ActiveMeeting from './ActiveMeeting';
import ALink from '@/components/UI/ALink.vue';
//import Assistant from '@/views/Meeting/Assistant';
import RelatedMeeting from './RelatedMeeting';
import MHeading from '@/components/UI/MHeading';
import HoverToolTip from '@/components/UI/HoverToolTip';
import MPlaceholder from '@/components/UI/MPlaceholder';
import MButton from '@/components/UI/MButton';
import {
  getMeetingAttendees,
  getRecurringInfoFromEvent,
  generateCollabUser,
} from '@/components/Utils';
import Defer from '@/mixin/Defer';

export default {
  name: 'Meeting',
  components: {
    ActiveMeeting,
    //Assistant,
    MHeading,
    RelatedMeeting,
    HoverToolTip,
    MPlaceholder,
    ALink,
    MButton,
  },
  mixins: [Defer()],
  props: {
    id: {
      type: String,
      required: false,
    },
    calendarId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      //showAssistant: false,
      activeMeetingEvent: null,
      activeNextMeetingDate: '',
      activeNextRecurringEvent: null,
      isThereReminderForNextMeeting: false,
      isThereAutoCancelForNextMeeting: false,
      loadingGoogleEvent: { loading: true, errorText: '' },
      isRelMeetLoaded: false,
      // these are the actual events
      relatedMeetings: [],
      userIsGuest: false,
      mainEventCalId: '',
      isPrivate: false,
      isPrivateReadonly: false,
      titleUpdatedCounter: 0, // counter used to re render title after change
      user: null, // collab user object
      isRefreshingToken: false,
      loadingMoreRelated: false,
      hideLoadMoreBtn: false,
      getNotesPrivateCounter: 0,
      getNotesPublicCounter: 0,
      lastRelatedNotesPublic: null,
      lastRelatedNotesPrivate: null,
      getIncompleteActionsFromRelatedPublic: 0,
      getIncompleteActionsFromRelatedPrivate: 0,
      hasIncompleteActions: { private: false, public: false },
      // number or editors loaded and total of incomplete actions in those editors
      initialisedRelatedEditors: {
        private: 0,
        public: 0,
        privateCnt: 0,
        publicCnt: 0,
      },
      incompleteActions: {},
      actionsToRemove: {},
      actionsToRemovePublicCounter: 0,
      actionsToRemovePrivateCounter: 0,
    };
  },
  mounted() {
    this.$scrollToTop();
    // redirect to signin instead of forbidden page in extension
    // using router guard caused repeated refresh for some reason
    if (this.isExtension && !this.isAuthenticated) {
      // console.log('** REDIR', this.isExtension, this.isAuthenticated);
      this.$router.replace({
        name: 'signin',
        query: { r: this.$route.path, cid: this.$route.query.cid },
      });
      return;
    }

    this.setCalendarId();

    this.evaluateIsPrivate({ firstLoad: true });

    this.getActiveMeetingEvent();
    this.user = generateCollabUser(this.$gAuth);
  },
  watch: {
    id: function () {
      // fetch data from Google

      this.setCalendarId();
      this.evaluateIsPrivate();
      this.relatedMeetings = []; // reset related meeting
      this.isRelMeetLoaded = false;
      this.lastRelatedNotesPublic = null;
      this.lastRelatedNotesPrivate = null;
      this.getActiveMeetingEvent();
      //
      this.getIncompleteActionsFromRelatedPublic = 0;
      this.getIncompleteActionsFromRelatedPrivate = 0;
      this.hasIncompleteActions = { private: false, public: false };
      this.initialisedRelatedEditors = {
        private: 0,
        public: 0,
        privateCnt: 0,
        publicCnt: 0,
      };
    },
    activeNextRecurringEvent: {
      handler() {
        this.getIsThereReminderForNextMeeting(
          this.mainEventCalId,
          this.activeNextRecurringEvent.id,
          this.id
        );
        this.getIsThereAutoCancelForNextMeeting(
          this.mainEventCalId,
          this.activeNextRecurringEvent.id
        );
      },
    },
  },
  computed: {
    timeMachineTitleHover() {
      const details = [];
      if (this.$store.getters['preferenceTimemachineRecurring'])
        details.push('recurring meetings');

      if (this.$store.getters['preferenceTimemachineGuests'])
        details.push('with the same guests');

      if (this.$store.getters['preferenceTimemachineSummary'])
        details.push('with similar names');

      const brackets = details.length ? ` (${details.join(' or ')})` : '';

      return `Past related meetings${brackets}`;
    },

    localPreferenceNewNoteOption() {
      return this.$store.getters['preferenceNotesDefaultJustMe'];
    },
    accountPlan() {
      return this.$store.getters['plan'];
    },
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
    hoverRight() {
      if (this.relatedMeetingsShowed.isEmpty)
        return {
          text: 'No notes found in related meetings',
          url: 'https://meetric.notion.site/The-Time-Machine-c54545d53da64d9ca054354128403b8e',
        };

      if (this.isPrivate)
        return {
          text: 'Past meetings in private view',
          url: 'https://meetric.notion.site/Shared-vs-Private-notes-d30498d1cc4b4b1089974a3a70e740ad',
        };

      return {
        text: 'Past meetings in shared view',
        url: 'https://meetric.notion.site/Shared-vs-Private-notes-d30498d1cc4b4b1089974a3a70e740ad',
      };
    },
    calendarIds() {
      return this.$store.getters['selectedCalendars'];
    },
    relatedMeetingsShowed() {
      let title = 'Time machine';
      let relatedMeetingsEmpty = false;
      let expand = false;
      if (
        this.isRelMeetLoaded &&
        ((this.isPrivate && this.relatedMeetingsPrivate.length == 0) ||
          (!this.isPrivate && this.relatedMeetingsPublic.length == 0))
      ) {
        expand = true;
        relatedMeetingsEmpty = true;
      }

      return {
        title: title,
        expandEditor: expand,
        isEmpty: relatedMeetingsEmpty,
      };
    },
    isAuthenticated() {
      return this.$gAuth.isAuthorized;
    },
    relatedMeetingsPublic() {
      if (!this.relatedMeetings) return [];

      return this.relatedMeetings;
      // return this.relatedMeetings.filter(
      //   (e) => e.extendedProperties.private.public == 'true'
      // );
    },
    relatedMeetingsPrivate() {
      if (!this.relatedMeetings) return [];

      return this.relatedMeetings;
      // return this.relatedMeetings.filter(
      //   (e) => e.extendedProperties.private.private == 'true'
      // );
    },
    baseURL() {
      return process.env.VUE_APP_PUBLIC_ENDPOINT;
    },
  },
  methods: {
    onRemoveActions($event) {
      // console.log('remove', $event);
      this.actionsToRemove = $event.actions;
      $event.isPrivate
        ? this.actionsToRemovePrivateCounter++
        : this.actionsToRemovePublicCounter++;
    },
    onGetLeftovers() {
      // reset mids to null
      const incompleteActions = this.relatedMeetings.reduce(
        (o, key) => Object.assign(o, { [key.id]: null }),
        {}
      );

      this.incompleteActions = incompleteActions;
      // inc counter to get actions
      if (this.isPrivate) {
        this.getIncompleteActionsFromRelatedPrivate++;
      } else {
        this.getIncompleteActionsFromRelatedPublic++;
      }
    },
    onIncompleteActions($event) {
      // console.log('e', $event);
      const key = $event.private ? 'private' : 'public';
      if ($event.initial) {
        // editor loaded
        this.initialisedRelatedEditors[key]++;
        this.initialisedRelatedEditors[`${key}Cnt`] += $event.actions.length;
        if (
          this.initialisedRelatedEditors[key] === this.relatedMeetings.length
        ) {
          this.$set(
            this.hasIncompleteActions,
            key,
            this.initialisedRelatedEditors[`${key}Cnt`] > 0
          );
        }
        return;
      }

      this.$set(this.incompleteActions, $event.mid, $event);
    },
    refreshLastMeeting(isPrivate) {
      isPrivate ? this.getNotesPrivateCounter++ : this.getNotesPublicCounter++;
    },
    lastMeetingNotes(notesObj, isPrivate) {
      if (isPrivate) {
        this.lastRelatedNotesPrivate = notesObj;
      } else {
        this.lastRelatedNotesPublic = notesObj;
      }
    },

    loadMoreRelated() {
      if (this.relatedMeetings.length == 0) return;

      this.loadingMoreRelated = true;
      const lastEvent = this.relatedMeetings.at(-1);
      // get just date, we dont care about exact time
      const lastStart = (
        lastEvent.start?.dateTime || lastEvent.start?.date
      ).split('T')[0];

      getMeetricRelatedMeetings(this.mainEventCalId, this.id, {
        skipid: lastEvent.id,
        skipdate: lastStart,
      }).then((r) => {
        if (r?.items) {
          this.relatedMeetings.push(...r.items);
          this.reportAccessForRelated(r.items);
        } else {
          this.hideLoadMoreBtn = true;
        }
        this.loadingMoreRelated = false;
      });
    },
    evaluateIsPrivate({ firstLoad = false } = {}) {
      if (this.isAuthenticated) {
        // load private instead of shared/public view
        if (
          firstLoad &&
          this.accountPlan > 1 && // premium only
          this.localPreferenceNewNoteOption
        ) {
          this.isPrivate = true;
          return;
        }
        this.isPrivate = this.$route.query.private !== undefined;
      } else {
        // not auth users can't change or see private
        this.isPrivate = false;
        this.isPrivateReadonly = true;
      }
    },
    refreshToken() {
      if (this.isRefreshingToken) return;

      if (this.$gAuth?.GoogleAuth?.currentUser?.get()) {
        this.isRefreshingToken = true;
        this.$gAuth.GoogleAuth.currentUser
          .get()
          .reloadAuthResponse()
          .then(() => (this.isRefreshingToken = false));
      }
    },
    onPatchMeetingTitle(newTitle) {
      patchMeetingTitle(this.mainEventCalId, this.id, {
        summary: newTitle,
      }).then((r) => {
        if (r?.id && r?.summary) {
          // update it locally so children get the change
          document.title = r.summary + ' | Meetric';
          this.$set(this.activeMeetingEvent, 'summary', r.summary);
          this.titleUpdatedCounter++;
        }
      });
    },
    setCalendarId() {
      // save calendar ID if provided
      if (this.calendarId || this.$route.query.cid) {
        this.mainEventCalId = this.calendarId
          ? this.calendarId
          : this.convertCalendarId(this.$route.query.cid);
      } else this.mainEventCalId = '';
    },

    onPrivateToggle(val) {
      this.isPrivate = val;
    },
    notifyIfCalendarNotEnabled(calendarId) {
      // emails from URL will be encoded most of the time but if not, encode it
      if (calendarId.includes('@')) calendarId = encodeURIComponent(calendarId);

      if (!this.calendarIds.includes(calendarId)) {
        this.$snack(
          'Enable this calendar in your settings to see it on your meeting list.'
        );
      }
    },
    convertCalendarId(cid) {
      const email = this.$gAuth?.basicProfile?.getEmail();
      return cid == email ? 'primary' : cid;
    },
    // onEditorInit() {
    //   if (this.totalRelEditorsLoaded < 0) this.totalRelEditorsLoaded += 2;
    //   else this.totalRelEditorsLoaded++;
    // },

    getActiveMeetingEvent() {
      this.loadingGoogleEvent.loading = true;
      this.loadingGoogleEvent.errorText = '';
      this.activeMeetingEvent = null;
      if (this.isAuthenticated) {
        const calIds = this.mainEventCalId
          ? this.mainEventCalId
          : this.calendarIds;

        getCalendarEventById(calIds, this.id).then((data) => {
          if (data) {
            this.mainEventCalId = this.convertCalendarId(data.cid);

            this.userIsGuest = true;
            this.notifyIfCalendarNotEnabled(this.mainEventCalId);
            this.setActiveMeeting(data.event);
            if (data.event?.summary)
              document.title = data.event.summary + ' | Meetric';
            this.loadingGoogleEvent.loading = false;
            this.getRelatedMeetings();
            let guestList = getMeetingAttendees(data.event).map((e) => e.email);

            const organizer = data.event?.organizer?.email
              ? [data.event?.organizer?.email]
              : [];

            // in backend adds both for private and public
            const payload = {
              emails: guestList.length > 0 ? guestList : organizer,
              title: data.event?.summary || '',
              start: data.event?.start?.dateTime || data.event?.start?.date,
            };
            addGuests(data.cid, this.id, payload);
          } else {
            // can not fetch event from google
            // lets try from meetric
            this.userIsGuest = false;
            this.getEventFromMeetric(this.id);
          }
        });
      } else {
        // not logged in
        this.userIsGuest = false;
        this.getEventFromMeetric(this.id);
      }
    },
    getEventFromMeetric(id) {
      // calendar ID doesn't matter for fetch from Meetric but we have to pass some
      const calId = this.mainEventCalId || 'primary';
      getCalendarEventFromMeetric(calId, id)
        .then((data) => {
          if (data?.error?.code == 403 || data?.error?.code == 401) {
            if (!this.isAuthenticated && this.$route.query.summary) {
              this.$router.replace({
                name: 'signin',
                query: {
                  r: this.$route.path,
                  cid: this.$route.query.cid,
                  summary: this.$route.query.summary,
                },
              });
            } else {
              this.$router.replace({ name: 'forbidden' });
            }
          } else if (data?.error?.code == 404 || !data.id) {
            this.$router.replace({ name: 'notfound', params: { id: id } });
          } else {
            this.setActiveMeeting(data);
            if (!this.mainEventCalId) this.mainEventCalId = 'primary';
            if (data.summary) document.title = data.summary + ' | Meetric';
            this.loadingGoogleEvent.loading = false;

            // users can't change to private
            this.isPrivate = false;
            this.isPrivateReadonly = true;
          }
        })
        .catch((e) => {
          console.error(e);
          this.loadingGoogleEvent.loading = false;
          this.loadingGoogleEvent.errorText = 'Can not find event details';
          this.$router.replace({ name: 'notfound', params: { id: id } });
        });
    },
    setActiveMeeting(event) {
      this.activeMeetingEvent = event;

      const weids = this.$store.getters['welcomeEventsId'];
      const isSample = weids.includes(event.id);
      this.$store.commit('setActiveEvent', { evt: event, sample: isSample });
      if (isSample) {
        const props = {
          meeting_id: event.id,
        };
        window.meetric.track('View sample', props);
      }

      // get next recuring event info (as prop to active meeting)
      this.getNextRecurringEvent();
    },
    getNextRecurringEvent() {
      const recurringInfo = getRecurringInfoFromEvent(this.activeMeetingEvent);
      this.activeNextRecurringEvent = null;

      if (recurringInfo.id && recurringInfo.end && this.userIsGuest) {
        // fetch only if user is guest, otherwise you can't fetch anyway
        getNextRecurringEvent(
          this.mainEventCalId,
          recurringInfo.id,
          recurringInfo.end
        ).then((r) => {
          if (!r) return;
          if (r.items.length == 1) {
            const e = r.items[0];
            this.activeNextRecurringEvent = e;
            if (e.start.dateTime) {
              this.activeNextMeetingDate = e.start.dateTime.split('T')[0];
            } else if (e.start.date) {
              this.activeNextMeetingDate = e.start.date;
            }
          }
        });
      }
    },
    getIsThereReminderForNextMeeting(calId, nextMeetingId, meetingId) {
      getMeetingReminder(calId, nextMeetingId, meetingId).then((r) => {
        this.isThereReminderForNextMeeting = r;
      });
    },
    getIsThereAutoCancelForNextMeeting(calId, nextMeetingId) {
      getMeetingAutoCancel(calId, nextMeetingId).then((r) => {
        this.isThereAutoCancelForNextMeeting = r;
      });
    },
    getRelatedMeetings() {
      getMeetricRelatedMeetings(this.mainEventCalId, this.id).then((r) => {
        this.relatedMeetings = r?.items || [];
        this.isRelMeetLoaded = true;
        if (!this.relatedMeetings.length) {
          this.lastRelatedNotesPublic = { agenda_items: '', notes: '' };
          this.lastRelatedNotesPrivate = { agenda_items: '', notes: '' };
        }
        this.reportAccessForRelated(this.relatedMeetings);
      });
    },
    //openAssistant() {
    //  this.showAssistant = !this.showAssistant;
    //},
    reportAccessForRelated(meetings) {
      if (!meetings || meetings.length == 0) return;

      meetings.forEach((m) => {
        const guestList = getMeetingAttendees(m).map((e) => e.email);
        const organizer = m?.organizer?.email ? [m.organizer.email] : [];

        const payload = {
          emails: guestList.length > 0 ? guestList : organizer,
          title: m?.summary || '',
          start: m?.start?.dateTime || m?.start?.date,
        };
        addGuests(this.mainEventCalId, m.id, payload);
      });
    },
  },
  beforeDestroy() {
    this.$store.commit('setActiveEvent', null);
  },
};
</script>
<style>
[contenteditable='true']:empty:before {
  @apply text-grey4;
  content: attr(data-placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}

.tooltip-enter-active {
  transition: opacity 0.5s ease 0.3s, transform 0.5s ease 0.3s;
}
.tooltip-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.tooltip-enter,
.tooltip-leave-to {
  width: 0;
  opacity: 0;
}
</style>
<style scoped>
.bottom-fade {
  background: linear-gradient(
    180deg,
    rgba(var(--basebackground), 1) 92%,
    rgba(var(--basebackground), 0) 100%
  );
}
</style>
