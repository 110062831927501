<template>
  <div>
    <div
      class="relative overflow-auto"
      style="padding: 1.3rem 1.75rem"
      :style="listType == 'notes' ? 'padding-bottom: 0' : ''"
    >
      <svg
        v-show="showTopArrowNotes"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute cursor-pointer"
        style="top: 5px; left: calc(50% - 15px)"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#d8d8d8"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        @click="scrollList(0, -30)"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="6 15 12 9 18 15" />
      </svg>
      <div
        class="text-grey2 font-medium pr-6 -mr-6 overflow-auto recipientsList"
        style="max-height: 7rem"
        :class="{ private: isPrivate }"
        ref="recipientsList"
      >
        <template v-for="recipient in recipients">
          <div class="flex justify-between text-base" :key="recipient.email">
            <div class="flex" :class="{ me: recipient.email == userEmail }">
              <span :title="recipient.email" data-recording-sensitive>
                {{ recipient.label }}
              </span>
              <div
                v-if="recipient.addedExtra"
                @click="removeAddedExtra(recipient.email)"
                class="cursor-pointer text-grey3 hover:text-alerterror ml-1"
              >
                ✗
              </div>
            </div>
            <div class="pl-2 ptext-right">
              <m-checkbox
                v-model="recipient.sendRecap"
                @input="sendUpdate"
              ></m-checkbox>
            </div>
          </div>
        </template>
      </div>

      <svg
        v-show="showBottomArrowNotes"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute cursor-pointer"
        style="top: calc(7rem + 8px); left: calc(50% - 15px)"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#d8d8d8"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        @click="scrollList(0, 30)"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="6 9 12 15 18 9" />
      </svg>
      <div v-if="listType == 'notes'" class="flex justify-between mb-3 mt-3">
        <div class="text-grey2 font-normal flex-grow">
          <input
            class="focus:outline-none placeholder-grey4 text-lg lg:text-base bg-transparent w-full"
            :class="{ invisible: !showAddRecipient }"
            v-model="addRecipientInput"
            placeholder="Add email, press ENTER"
            v-on:keyup="addRecipient"
            id="addRecipientInput"
          />
        </div>

        <div class="text-right cursor-pointer font-normal text-grey3 text-sm">
          <span v-if="!recipients.every((x) => x.sendRecap)" class="flex pl-2">
            <div @click="setAllRecap(true)">Select all</div>
          </span>
          <span v-else class="">
            <div @click="setAllRecap(false)">Deselect all</div>
          </span>
        </div>
      </div>
      <template v-if="listType == 'notes'">
        <hr class="w-full border-grey5" />
        <div class="flex justify-between mb-3 mt-3">
          <div class="text-grey1 flex-grow">
            <input
              class="font-normal focus:outline-none placeholder-grey4 text-lg lg:text-base bg-transparent w-full"
              :value="emailSubject"
              id="emailSubject"
              @input="onSubjectInput($event.target.value)"
              placeholder="Write here to add a subject to this email"
            />
          </div>

          <div class="text-right cursor-pointer font-normal text-grey3 text-sm">
            <span>
              <div @click="focusSubjectInput">Edit subject</div>
            </span>
          </div>
        </div>
        <hr class="w-full border-grey5" />
      </template>
    </div>
    <div
      v-if="listType == 'notes'"
      class="relative overflow-auto"
      style="padding: 1.3rem 1.75rem; padding-top: 0"
    >
      <div
        contenteditable
        :id="`meetric-meeting-note-${isPrivate}`"
        data-placeholder="Write here to add a note to the email"
        class="rounded pt-3 text-grey1 font-normal pr-6 -mr-6 h-20 focus:outline-none overflow-auto text-lg lg:text-base show-placeholder"
        data-recording-sensitive
      ></div>
    </div>
  </div>
</template>

<script>
import MCheckbox from '@/components/UI/MCheckbox';
import { getName } from '@/components/Utils';

export default {
  name: 'EndMeetingModalList',
  components: { MCheckbox },
  props: {
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
    userEmail: {
      type: String,
      required: true,
    },
    defaultSubject: {
      type: String,
      required: false,
      default: '',
    },

    listType: {
      type: String,
      required: false,
      default: 'remind', // 'notes' or 'remind'
    },
    attendees: {
      type: Array,
      required: true,
      default: () => [],
    },
    showAddRecipient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      recipients: [],
      addRecipientInput: '',
      recipientsScrolledDown: false,
      recipientsScrolledTop: true, //list is scrolled top by default
      emailSubject: '',
      emailSubjectInputReadonly: true,
    };
  },
  watch: {
    defaultSubject: function (val) {
      this.emailSubject = val;
    },
  },
  mounted() {
    this.emailSubject = this.defaultSubject;
    Promise.all(
      this.attendees.map(async (e) => {
        return getName(e.email).then((label) => {
          //let sendRecap = this.listType == 'notes'; // default true for notes, false for reminders
          let sendRecap = true;
          if (this.isPrivate) sendRecap = e.email == this.userEmail;

          if (
            this.isPrivate &&
            this.listType == 'remind' &&
            e.email != this.userEmail
          ) {
            // empty block is ugly, but my head hurt when I tried to correctly invert the condition
            // we don't wanna display other users if it is PRIVATE+REMINDER
          } else {
            this.recipients.push({
              email: e.email,
              label: e.displayName ? e.displayName : label,
              sendRecap: sendRecap,
              addedExtra: false,
            });
          }
        });
      })
    ).then(() => {
      this.recipients.sort((a, b) => a.label.localeCompare(b.label));
      this.sendUpdate();
    });

    // sort by name,email

    this.$refs.recipientsList.addEventListener('scroll', this.onScroll);
  },
  computed: {
    showTopArrowNotes() {
      return this.recipients.length >= 4 && !this.recipientsScrolledTop;
    },
    showBottomArrowNotes() {
      return this.recipients.length >= 4 && !this.recipientsScrolledDown;
    },
  },
  methods: {
    focusSubjectInput() {
      this.$nextTick(() => {
        document.querySelector('#emailSubject').focus();
      });
    },
    onSubjectInput(value) {
      this.emailSubject = value;
      this.$emit('subjectupdate', this.emailSubject);
    },
    sendUpdate() {
      this.$emit('update', this.recipients);
    },
    scrollList(x = 0, y = 0) {
      this.$refs.recipientsList.scrollBy({
        top: y,
        left: x,
        behavior: 'smooth',
      });
    },
    onScroll() {
      const elem = this.$refs.recipientsList;
      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.recipientsScrolledDown = true;
      } else this.recipientsScrolledDown = false;

      if (elem.scrollTop == 0) {
        this.recipientsScrolledTop = true;
      } else this.recipientsScrolledTop = false;
    },
    addRecipient(e) {
      if (e.keyCode === 13) {
        // on ENTER key
        const recipient = this.addRecipientInput.trim();
        if (this.isValidEmail(recipient)) {
          if (!this.recipients.some((item) => item.email == recipient)) {
            // push it only if not in array already
            this.recipients.push({
              email: recipient,
              label: recipient,
              sendRecap: true,
              addedExtra: true,
            });
          }
          this.sendUpdate();
        }
        this.addRecipientInput = '';

        this.$nextTick(function () {
          // scroll to last element when added new one
          this.$refs.recipientsList.scrollTop =
            this.$refs.recipientsList.scrollHeight -
            this.$refs.recipientsList.offsetHeight;
        });
      }
    },
    isValidEmail(email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
        email
      );
    },
    setAllRecap(boolVal) {
      this.recipients.forEach((recipient) => {
        this.$set(recipient, 'sendRecap', boolVal);
      });
      // window.meetric.track('Toggle all recap', value);
      this.sendUpdate();
    },
    removeAddedExtra(email) {
      this.recipients = this.recipients.filter(
        (recipient) => recipient.email !== email
      );
      this.sendUpdate();
    },
  },
  beforeDestroy() {
    this.$refs.recipientsList.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style scoped>
#emailSubject:placeholder-shown {
  font-weight: 400;
}

.recipientsList > div {
  @apply mb-3;
}
.recipientsList > div:last-child {
  @apply mb-0;
}
.recipientsList.private > div > div:not(.me) {
  @apply text-grey4 dark:text-grey5;
}

.show-placeholder:empty:before {
  @apply text-grey4;
  content: attr(data-placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}
</style>
